
import { FinanceInfo } from "@/core/models/FinanceInfo"
import ApiService from "@/core/services/ApiService"
import { defineComponent, onMounted, ref } from "vue"
import { currencyForm } from "@/core/helpers/currencyformat"

export default defineComponent({
  components: {},
  name: "info-board",
  inheritAttrs: false,
  props: [],
  setup() {
    const wsize = ref(24)

    const selectedBranches = ref([])
    const businessDetail = ref([])
    const selectedRangeType = ref(3)
    const financeInfo = ref<FinanceInfo>()

    onMounted(async () => {
      await getBusinessDetail()
    })

    const getBusinessDetail = async () => {
      const { data } = await ApiService.get("business/detail")
      businessDetail.value = data
        .filter(x => x.branches.length > 0)
        .map(x => ({ branches: x.branches, title: x.title, id: x.id }))

      // selectedBranches.value = []

      // // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // businessDetail.value.forEach((element: any) => {
      //   businessDetail.value.forEach((element2: any) => {
      //     selectedBranches.value.push(element2.id as never)

      //     console.log("element2")
      //     console.log(element2.id)
      //   })
      // })
      // console.log("selectedBranches")
      // console.log(selectedBranches.value)
      // getWidgetInfo()
    }

    async function getWidgetInfo() {
      const config = {
        branchIds: selectedBranches.value,
        rangeType: selectedRangeType.value,
      }
      const { data } = await ApiService.post("finance/financialinfo-widget", config)
      financeInfo.value = data
    }

    return {
      wsize,
      selectedBranches,
      getBusinessDetail,
      businessDetail,
      getWidgetInfo,
      financeInfo,
      currencyForm,
      selectedRangeType,
    }
  },
})
