import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InfoBoard = _resolveComponent("InfoBoard")!
  const _component_FastTransactions = _resolveComponent("FastTransactions")!
  const _component_StudentAction = _resolveComponent("StudentAction")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createBlock(_component_el_row, { gutter: 20 }, {
    default: _withCtx(() => [
      _createVNode(_component_InfoBoard),
      _createVNode(_component_FastTransactions),
      (_ctx.checkWidgetPermission(_ctx.WidgetEnum.StudentQuickSearch))
        ? (_openBlock(), _createBlock(_component_StudentAction, { key: 0 }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}