
import { defineComponent, ref } from "vue"
import InfoBoard from "@/components/widgets/InfoBoard.vue"
import StudentAction from "@/components/widgets/StudentAction.vue"
import FastTransactions from "@/components/widgets/FastTransactions.vue"
import { WidgetEnum } from "@/core/enums/widget-enum"
import { checkWidgetPermission } from "@/core/helpers/widget"

export default defineComponent({
  components: {
    InfoBoard,
    StudentAction,
    FastTransactions,
  },
  name: "dashboard",
  setup() {
    const showResult = ref(false)
    const w1size = ref(12)
    const w2size = ref(12)
    const w3size = ref(12)

    const areas = [
      {
        country: "Russia",
        area: 12,
      },
      {
        country: "Canada",
        area: 7,
      },
      {
        country: "USA",
        area: 7,
      },
      {
        country: "China",
        area: 7,
      },
      {
        country: "Brazil",
        area: 6,
      },
      {
        country: "Australia",
        area: 5,
      },
      {
        country: "India",
        area: 2,
      },
      {
        country: "Others",
        area: 55,
      },
    ]

    const dataSource = [
      {
        country: "Şirinevler",
        medals: 110,
      },
      {
        country: "Maltepe",
        medals: 100,
      },
      {
        country: "Bakırköy",
        medals: 72,
      },
      {
        country: "Pendik",
        medals: 47,
      },
      {
        country: "Defaköy",
        medals: 46,
      },
      {
        country: "Avcılar",
        medals: 41,
      },
      {
        country: "Küçükçekmece",
        medals: 40,
      },
      {
        country: "Silivri",
        medals: 31,
      },
    ]

    return {
      showResult,
      areas,
      dataSource,
      w1size,
      w2size,
      w3size,
      WidgetEnum,
      checkWidgetPermission,
    }
  },
})
