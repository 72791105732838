
import { defineComponent, ref } from "vue"
import { useRouter } from "vue-router"

export default defineComponent({
  components: {},
  name: "fast-transactions",
  inheritAttrs: false,
  props: [],
  setup() {
    const wsize = ref(24)

    const router = useRouter()

    function goToFinancePage(pageName: string) {
      const validPages: string[] = [
        "expense",
        "revenue",
        "payment",
        "collection",
        "account-virman",
        "branch-virman",
      ]

      if (validPages.includes(pageName)) {
        router.push(`/finance/${pageName}`)
      } else {
        console.error("Invalid finance page name.")
      }
    }

    return {
      wsize,
      goToFinancePage,
    }
  },
})
