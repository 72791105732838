
import { PersonNviYearDto } from "@/core/models/PersonNviYearDto"
import { Semester } from "@/core/models/Semester"
import ApiService from "@/core/services/ApiService"
import { DateFormatTypes, dateForm } from "@/core/helpers/dateformat"
import { Edit, Money, Plus } from "@element-plus/icons-vue"
import { FormInstance } from "element-plus"
import { defineComponent, onMounted, ref } from "vue"
import { SelectModel } from "@/core/models/SelectModel"
import { getRule, RuleTypes } from "@/core/helpers/rules"

export default defineComponent({
  components: {},
  name: "student-action",
  inheritAttrs: false,
  props: [],
  setup() {
    const studentList = ref<PersonNviYearDto[]>([])
    const isStudentDataLoading = ref(false)
    const ruleFormRef = ref<FormInstance>()
    const wsize = ref(24)
    const showResult = ref(false)
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const activeSemester = ref<string>()
    const semesterList = ref<Semester[]>([])
    const activeBusinessId = ref<string>()
    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const studentData = ref<PersonNviYearDto>({
      isForeign: false,
      _pageNumber: 1,
      _pageSize: 10,
    } as PersonNviYearDto)

    onMounted(async () => {
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      isBusinessSelectLoading.value = false
      if (businessList.value.length === 1) {
        activeBusinessId.value = businessList.value[0].id
        OnChangeBusiness(activeBusinessId.value)
      }
    }

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      isBranchSelectLoading.value = false
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length === 1) {
        studentData.value.branchId = branchList.value[0].id
        getStudentList(ruleFormRef.value)
      }
      await getSemesterList(businessId)
    }

    const getSemesterList = async businessId => {
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data.map(x => ({ id: x.id, name: x.name, isActive: x.isActive }))
      activeSemester.value = semesterList.value.find(x => x.isActive === true)?.id
    }

    const OnChangeBusiness = async businessId => {
      studentData.value.branchId = ""
      await getBranchList(businessId)
    }

    const getStudentList = async formEl => {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          isStudentDataLoading.value = true
          const searchFilter = {
            ...studentData.value,
            semesterId: activeSemester.value,
          }
          const { data } = await ApiService.post("student/search", searchFilter)
          showResult.value = true
          studentList.value = data._data
          totalCount.value = data._totalData
          studentData.value._pageCount = data._pageCount
          isStudentDataLoading.value = false
        }
      })
    }

    const rules = ref({
      branchId: getRule(RuleTypes.SELECT, "Şube"),
    })

    const totalCount = ref<number>(0)

    const handleSizeChange = (val: number) => {
      const data = studentData.value
      data._pageSize = val
      studentData.value = data
      getStudentList(ruleFormRef.value)
    }
    const handleCurrentChange = (val: number) => {
      const data = studentData.value
      data._pageNumber = val
      studentData.value = data
      getStudentList(ruleFormRef.value)
    }

    const clearForm = formEl => {
      activeBusinessId.value = ""
      activeSemester.value = ""
      formEl.resetFields()
      showResult.value = false
    }

    return {
      dateForm,
      clearForm,
      getBranchList,
      getStudentList,
      OnChangeBusiness,
      handleSizeChange,
      handleCurrentChange,
      Edit,
      Money,
      Plus,
      rules,
      wsize,
      showResult,
      studentData,
      studentList,
      totalCount,
      branchList,
      ruleFormRef,
      businessList,
      semesterList,
      activeSemester,
      DateFormatTypes,
      activeBusinessId,
      isStudentDataLoading,
      isBranchSelectLoading,
      isBusinessSelectLoading,
    }
  },
})
