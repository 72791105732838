export enum WidgetEnum {
  StudentQuickSearch,
  ExpenseReceipt,
  IncomeReceipt,
  Payment,
  Collection,
  Transfer,
  MainCashOut,
  FinancialStatus,
  FinancialSummaries,
}
